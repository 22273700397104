import * as React from 'react';
import { Link } from 'gatsby';
import Layout from '../components/layout';

const NotFoundPage = () => {
  return (
    <Layout>
      <section>
        <h1>Page not found</h1>
        <p>Sorry, we couldn't find what you were looking for</p>
        <p><Link to='/'>Back home</Link></p>
      </section>
    </Layout>
  );
};

export default NotFoundPage;
